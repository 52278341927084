.Footer {
  width: 95%;
  margin: 0 auto;
}

.FWidth {
  width: 24px;
  height: 24px;
}

.FContacts {
  margin-top: 30px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.FTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.FSubTitle {
  font-size: 15px;
}

.FIcon {
  margin-right: 10px;
}

@media screen and (max-width: 860px) {
  .Footer {
    margin-top: 30px;
    overflow: hidden;
  }

  .FContacts {
    display: block;
  }

  .FContacts div {
    margin-bottom: 30px;
  }
}
