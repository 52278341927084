.Navbar {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px 0;
}

.Circle {
  width: 25px;
  height: 25px;
  background-color: #eea302;
  border-radius: 50%;
  margin-right: 10px;
}

.Logo {
  display: flex;
  align-items: end;
  color: #000;
}

.Title {
  font-size: 22px;
  font-weight: 600;
  margin-right: 10px;
}

.SubTitle {
  font-size: 15px;
}

.Links .Link {
  color: #000;
}

.Link + .Link::before {
  content: "|";
  padding: 0 10px;
}

.HamburgerMenu {
  display: none;
  z-index: 2;
  cursor: pointer;
}

.Menu {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  /* background-color: red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  /* justify-content: center; */
  z-index: 1;
  box-sizing: border-box;
}

.NLink {
  color: #000;
  font-size: 25px;
  padding: 10px 20px;
}

@media screen and (max-width: 1000px) {
  .Navbar {
  }

  .Links {
    display: none;
  }

  .HamburgerMenu {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
