.Home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.Photo {
  border-radius: 50%;
  /* width: 400px; */
  /* filter: grayscale(100%); */
  margin-right: 30px;
}

.Details {
  width: 420px;
}

.HTitle {
  font-size: 100px;
  font-weight: bold;
  margin-left: -10px;
  margin-bottom: 20px;
}

.HSubTitle {
  font-size: 22px;
  font-weight: bold;
  /* margin-left: 10px; */
  margin-bottom: 10px;
}

.HParagraph {
  /* margin-left: 10px; */
  font-size: 16px;
}

.HLinks {
  /* margin-left: 10px; */
  margin-top: 50px;
}

.HLink {
  color: #000;
  font-weight: bold;
  font-size: 20px;
  margin-right: 15px;
  padding: 20px 20px;
  border-radius: 20px;
  border: 1px solid #000;
  transition: all 0.3s;
}

.HLink:hover {
  background-color: #fff;
}

.yellow {
  background-color: #eea302;
}

.red {
  background-color: #ff3b25;
}

.cyan {
  background-color: #80d8da;
}

@media screen and (max-width: 1000px) {
  .Photo {
    width: 400px;
    /* margin-bottom: 250px; */
  }
}

@media screen and (max-width: 860px) {
  .Home {
    /* width: 95%; */
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    /* margin-top: 20px; */
  }

  .Photo {
    width: 370px;
    margin-right: 0;
    /* margin-bottom: 20px; */
  }

  .Details {
    width: 95%;
  }

  .HTitle {
    font-size: 60px;
    margin-left: -5px;
  }

  .HSubTitle {
    font-size: 30px;
    margin-left: 0px;
  }

  .HParagraph {
    font-size: 24px;
    margin-left: 0px;
  }

  .HLinks {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 25px 0;
  }

  .HLink {
    /* padding: 20px 20px; */
    width: 20%;
    text-align: center;
    margin-right: 0;
  }
}
